.NO_MARGINS {
  margin: 0 !important;
}

.INVISIBLE {
  display: none;
}

.UNSTYLED_LINK {
  text-decoration: inherit !important;
  color: inherit !important;
}

[type='file'] {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  overflow: hidden;
  padding: 0;
  position: absolute !important;
  white-space: nowrap;
  width: 1px;

  &:focus {
    + label {
      box-shadow:
        0 0 0 1px #137cbd,
        0 0 0 3px rgba(19, 124, 189, 0.3) !important;
    }
  }
}
