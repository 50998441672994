@import '~normalize.css/normalize.css';
@import 'src/assets/stylesheets/highcharts';
@import 'src/assets/stylesheets/globals';
@import '~ag-grid-enterprise/styles/ag-grid.css';
@import '~ag-grid-enterprise/styles/ag-theme-alpine.css';
@import '~allotment/dist/style.css';

$primary: #27aae1;
$green: #00af68;
$blue: #356cec;
$disabled: #e0e0e0;
$white: #ffffff;
$red: #d32f2f;
$muted: #333333;

body {
  margin: 0;
  background-color: $white;
}

.month-year-nav + div {
  display: inline-grid;
  grid-template-columns: repeat(3, 1fr);
}

.quarter-year-nav + div {
  display: inline-grid;
  grid-template-columns: repeat(4, 1fr);
}

.years-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
}

td {
  word-break: break-word;
}

.disable-btn-effect {
  background-image: none !important;
  box-shadow: none !important;
}

.MuiContainer-root {
  .output-table-header {
  }
}

.ag-group-value {
  white-space: nowrap;
}
